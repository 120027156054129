* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Nohemi, sans-serif;
  color: #432818;
  background-color: #fff4eb;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Changed from center to flex-start for scrollable content */
  min-height: 100vh;
  overflow-y: auto; /* Enables vertical scrolling */
}

.container {
  width: 60vw;
  max-width: 60vw;
  padding: 20px;
  margin: 20px auto; /* Center aligns container */
}

.header-container {
  width: 60vw;
  max-width: 60vw;
  padding: 20px 20px 0 20px;
  margin: 20px auto;
  position: relative; /* Changed from absolute to relative */
  text-align: left;
}

.list-container {
  width: 60vw;
  max-width: 60vw;
  padding: 0 20px;
  margin: 0 auto;
  position: relative; /* Changed from absolute to relative */
}

header {
  margin-bottom: 20px;
}

header h1 {
  font-size: calc(2rem + 2vw);
  margin-bottom: 0.5rem;
  color: #432818;
}

header p {
  font-size: calc(1.5rem + 1.5vw);
  color: #432818;
}

header a {
  color: #432818;
  text-decoration: none;
  font-weight: bold;
}

.about-link {
  display: inline-block;
  font-size: calc(0.9rem + 2vw);
  text-decoration: none;
  margin-top: 10px;
}

.search-bar {
  width: 100%;
  padding: 8px;
  font-size: calc(1rem + 2vw);
  margin: 20px 0;
  border: 1px solid #337357;
  background-color: #43281873;
}

.dev,
.music {
  color: #432818;
  text-decoration: none;
  font-weight: bold;
  background: none;
  border: none;
  font-size: calc(1rem + 1vw);
  font-family: Nohemi, sans-serif;
  cursor: pointer;
  text-align: left;
  display: inline-block;
  font-size: calc(0.9rem + 2vw);
  text-decoration: none;
  margin-top: 2.5rem;
  width: calc(10rem + 2vw);
}

.selected {
  color: #337357;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .container,
  .header-container,
  .list-container {
    width: 90vw; /* Adjust width for smaller screens */
    max-width: 100%; /* Ensure full-width on small screens */
    padding: 10px; /* Reduce padding for mobile */
    margin: 0 auto; /* Center aligns container */
    text-align: left; /* Align content to the left */
  }

  header h1 {
    font-size: 2.5rem; /* Adjust font size for mobile */
  }

  header p {
    font-size: 1rem;
  }

  .about-link {
    font-size: 1rem; /* Smaller font for mobile */
  }

  .dev,
  .music {
    font-size: 1.5rem;
    width: 100%; /* Full width buttons for mobile */
    margin-top: 1.5rem;
  }
  
}

a {
  color: #432818;
}
