@font-face {
    font-family: 'meltbrown';
    src: url('./fonts/meltbrown-demo.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Nohemi";
    src: url("fonts/Nohemi-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Nohemi";
    src: url("fonts/Nohemi-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Nohemi";
    src: url("fonts/Nohemi-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Nohemi";
    src: url("fonts/Nohemi-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Nohemi";
    src: url("fonts/Nohemi-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Nohemi";
    src: url("fonts/Nohemi-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Nohemi";
    src: url("fonts/Nohemi-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Nohemi";
    src: url("fonts/Nohemi-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Nohemi";
    src: url("fonts/Nohemi-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

