.projects-table {
  width: 100%;
  border-collapse: collapse;
}

.projects-table th,
.projects-table td {
  padding: 12px 8px;
  border-bottom: 1px solid #337357;
  text-align: left;
  color: #432818;
}

.projects-table th {
  font-weight: bold;
  color: #432818;
}

.map-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.map-cell a {
  color: #432818;
  text-decoration: none;
  font-weight: bold;
}

.map-cell span {
  margin-right: 10px;
}

#waveform ::part(cursor):before {
  content: "🫶";
}
#waveform ::part(region) {
  font-family: fantasy;
}

.waveform-cell {
  max-width: 70%;
  width: 70%;
}

.song-name {
  max-width: 30%;
  width: 30%;
  font-size: calc(0.8rem + 0.3vw);
}

.inline-container {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  width: 100%;
}

#waveform {
  margin-left: 0.5rem;
  width: 100%;
}

.play {
  padding: 8px 12px;
  color: #337357;
  background-color: #fff4eb;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 1rem;
}

.pause {
  color: #432818;
}

.description {
  position: relative;
  display: block;
}

.projects-table {
  width: 100%;
  border-collapse: collapse;
}

.projects-table th,
.projects-table td {
  padding: 12px 8px;
  border-bottom: 1px solid #337357;
  text-align: left;
  color: #432818;
}

.projects-table th {
  font-weight: bold;
  color: #432818;
}

.main-row {
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
  font-size: calc(1rem + 0.3vw);
  
}

.song-name {
  max-width: unset;
  width: auto;
  transition: background-color 0.3s;
  font-weight: 600;
  font-size: calc(1rem + 0.3vw);
}

.description-row td {
  padding: 12px 8px;
  color: #432818;
  width: 100%; /* Make sure it spans entire row */
  box-sizing: border-box;
  display: table-cell; /* Ensure it's treated as a table cell */
}

.description-row p {
  width: 70%;
  line-height: calc(1rem + 0.5vw);
}

.description {
  color: #555;
  font-size: calc(0.6rem + 0.5vw);
  padding-top: 0 !important;
  
}

.empty {
  width: 0;
}

.row-expanded {
  border-bottom: none !important;
}

.exp-type {
  width: 20%;
}

.header-cell {
  cursor: pointer;
  text-decoration: none;
}

.header-cell:hover {
  cursor: pointer;
  text-decoration: underline;
}
